import Roles from '@/constants/Roles';
import Routes from '@/constants/Routes';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useMemo } from 'react';

const useAuth = () => {
    const { user, checkSession, isLoading, error } = useUser() as any;

    const handleLogout = () => {
        window.location.href = Routes.LOGOUT;
    };

    return useMemo(
        () => ({
            user: user?.user,
            update: checkSession,
            isLoading,
            error,
            accessToken: user?.session?.accessToken,
            isAuthenticated: Boolean(user?.session?.accessToken),
            role: user?.session?.role,
            isAdmin: user?.session?.role === Roles.ADMIN,
            logout: handleLogout,
        }),

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user?.session?.accessToken, checkSession, error, isLoading, user?.session?.role, user]
    );
};

export default useAuth;
